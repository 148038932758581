import { extendTheme } from '@chakra-ui/react';
import { HEADING_FONT, HEADING_ALTERNATIVE, BODY_FONT } from '@/constants/fonts';
import {
  BG_SECONDARY,
  BG_PRIMARY,
  BG_SECONDARY_DARK,
  TEXT_DECORATION,
  TEXT_PRIMARY,
  TEXT_SECONDARY,
  DEFAULT_BUTTON,
  TEXT_PRIMARY_LIGHTER,
} from '@/constants/colors';

/**
 * override chackra-ui for personalize purpose
 * see the docs: https://chakra-ui.com/guides/using-fonts
 */
const theme = {
  fonts: {
    heading: HEADING_FONT,
    body: BODY_FONT,
    headingAlternative: HEADING_ALTERNATIVE,
    serif: BODY_FONT,
    cursive: HEADING_FONT,
    jost: `'Jost', sans-serif;`,
  },
  colors: {
    bgPrimary: BG_PRIMARY,
    bgSecondary: BG_SECONDARY,
    bgAlternative: TEXT_DECORATION,
    btnColor: DEFAULT_BUTTON,
    mainColor: BG_SECONDARY,
    mainColorText: TEXT_PRIMARY,
    secondaryColorText: TEXT_SECONDARY,
    mainColorTextLighter: TEXT_PRIMARY_LIGHTER,
    transparent: 'transparent',
    theme: {
      bgPrimaryText: TEXT_PRIMARY,
      bgPrimary: BG_PRIMARY,
      bgSecondary: BG_SECONDARY,
      bgSecondaryDark: BG_SECONDARY_DARK,
    },
    button: {
      secondary: {
        500: BG_SECONDARY,
      },
      secondaryDark: {
        500: BG_SECONDARY_DARK,
      },
    },
  },
  shadows: {
    outline: BG_SECONDARY_DARK,
  },
  components: {
    Radio: {
      variants: {
        custom: {
          control: {
            w: '22px',
            h: '22px',
            backgroundColor: 'bgSecondary',
            borderColor: 'black',
            borderWidth: '1px',
            _checked: {
              bg: 'bgSecondary',
              borderColor: 'bgSecondary',
              color: 'white',
              _hover: {
                bg: 'bgSecondary',
                borderColor: 'bgSecondary',
                color: 'white',
              },
            },
          },
        },
      },
    },
  },
};

export default extendTheme(theme);
